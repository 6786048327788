<h1 class="mb-3">Link Library</h1>
<p class="mb-3">
  You can find me online on any of the profiles below, where I will be sharing
  thoughts, code snippets and design pieces.
</p>
<ul>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://codepen.io/samwilliscreative"
      >Codepen</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://dribbble.com/samwilliscreative"
      >Dribble</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://www.facebook.com/samwilliscreative"
      >Facebook</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://github.com/samwilliscreative"
      >GitHub</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://www.instagram.com/samwilliscreative/"
      >Instagram</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://twitter.com/swilliscreative"
      >Twitter</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_black"
      href="https://stackoverflow.com/users/3909886/sam-willis?tab=profile"
      >Stack Overflow</a
    >
  </li>
</ul>
