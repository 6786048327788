<h1 class="mb-3">Sam Willis Creative</h1>
<p class="mb-3">Specialising in graphic design, digital design, and web development, I work intelligently with clients to provide their business with something exceptional.</p>
<p class="mb-5">You can contact me via <a title="Email Sam Willis Creative" href="mailto:info@samwilliscreative.com">info@samwilliscreative.com</a> or <a title="Message me through Facebook" href="https://m.me/samwilliscreative" target="_blank">Facebook Messenger</a></p>
<ul>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_blank"
      href="https://www.facebook.com/samwilliscreative"
      >Facebook</a
    >
  </li>
  <li class="mb-3">
    <a
      class="btn btn-secondary btn-lg btn-block"
      target="_blank"
      href="https://www.instagram.com/samwilliscreative/"
      >Instagram</a
    >
  </li>
</ul>
