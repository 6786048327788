import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Sam Willis Creative - design and development services'
    );
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'Specialising in graphic design, digital design, and web development, I work intelligently with clients to provide their business with something exceptional.',
      },
      { name: 'robots', content: 'index, follow' },
    ]);
  }
}
