import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-link-library',
  templateUrl: './link-library.component.html',
  styleUrls: ['./link-library.component.scss'],
})
export class LinkLibraryComponent implements OnInit {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle('Social links - Sam Willis Creative');
    this.metaService.addTags([
      {
        name: 'description',
        content:
          'Links to online social profiles related to Sam Willis Creative',
      },
      { name: 'robots', content: 'index, follow' },
    ]);
  }
}
