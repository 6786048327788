<div class="overlay"></div>
<img
  class="background-img"
  src="/assets/img/sam-willis-creative.jpg"
  alt="Sam Willis Creative"
/>

<div class="masthead py-5">
  <div class="masthead-bg"></div>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-12 my-auto">
        <div class="masthead-content py-5 py-md-0">
          <svg
            class="logo"
            width="100%"
            height="100%"
            viewBox="0 0 397 171"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="
              fill-rule: evenodd;
              clip-rule: evenodd;
              stroke-linejoin: round;
              stroke-miterlimit: 2;
            "
          >
            <defs>
              <linearGradient
                id="gradient-symbol"
                x1="21%"
                y1="0%"
                x2="79%"
                y2="100%"
              >
                <stop
                  offset="25%"
                  style="stop-color: #db6b2a; stop-opacity: 1"
                />
                <stop
                  offset="65%"
                  style="stop-color: #ae1a31; stop-opacity: 1"
                />
              </linearGradient>
              <linearGradient
                id="gradient-line"
                x1="79%"
                y1="0%"
                x2="10%"
                y2="100%"
              >
                <stop
                  offset="25%"
                  style="stop-color: #db6b2a; stop-opacity: 1"
                />
                <stop
                  offset="65%"
                  style="stop-color: #ae1a31; stop-opacity: 1"
                />
              </linearGradient>
              <linearGradient
                id="gradient-samwillis"
                gradientUnits="userSpaceOnUse"
                x1="21%"
                y1="0%"
                x2="79%"
                y2="100%"
              >
                <stop
                  offset="25%"
                  style="stop-color: #db6b2a; stop-opacity: 1"
                />
                <stop
                  offset="65%"
                  style="stop-color: #ae1a31; stop-opacity: 1"
                />
              </linearGradient>
              <linearGradient
                id="gradient-creative"
                gradientUnits="userSpaceOnUse"
                x1="21%"
                y1="0%"
                x2="79%"
                y2="100%"
              >
                <stop
                  offset="45%"
                  style="stop-color: #ae1a31; stop-opacity: 1"
                />
                <stop
                  offset="85%"
                  style="stop-color: #db6b2a; stop-opacity: 1"
                />
              </linearGradient>
            </defs>
            <rect
              id="Artboard1"
              x="0"
              y="0"
              width="396.8"
              height="170.229"
              style="fill: none"
            />
            <g id="Artboard11" serif:id="Artboard1">
              <g class="logo__samwillis">
                <path
                  d="M106.114,22.294C105.4,18.472 104.901,16.278 104.617,15.712C103.76,13.447 102.119,12.315 99.695,12.315C97.982,12.315 96.769,13.518 96.055,15.924C95.627,17.198 95.413,18.472 95.413,19.746C95.413,24.558 99.193,31.565 106.753,40.765C114.313,49.966 118.092,57.539 118.092,63.484C118.092,67.872 116.573,72.33 113.535,76.86C110.497,81.389 106.293,83.654 100.924,83.654C96.826,83.654 93.081,82.203 89.69,79.301C86.299,76.4 84.391,72.543 83.967,67.73L83.543,63.059L94.565,61.36C95.272,65.182 95.766,67.376 96.049,67.942C96.897,70.207 98.522,71.339 100.924,71.339C102.196,71.339 103.185,70.702 103.891,69.429C104.88,67.447 105.375,65.465 105.375,63.484C105.375,60.086 103.573,56.052 99.97,51.381C96.367,46.71 92.799,42.11 89.266,37.581C85.451,31.777 83.543,26.045 83.543,20.383C83.543,17.835 83.897,15.216 84.603,12.527C87.005,4.176 92.163,0 100.076,0C104.174,0 107.883,1.415 111.204,4.246C114.524,7.077 116.397,10.97 116.821,15.924L117.245,20.595L106.114,22.294Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M162.022,76.01C162.587,79.408 161.457,81.743 158.631,83.017C157.642,83.442 156.794,83.654 156.087,83.654C152.979,83.654 150.718,80.328 149.305,73.675C148.316,67.588 147.397,61.502 146.549,55.415L137.859,55.415C137.011,61.502 136.093,67.588 135.104,73.675C133.691,80.328 131.43,83.654 128.321,83.654C127.614,83.654 126.767,83.442 125.778,83.017C122.951,81.743 121.821,79.408 122.386,76.01L136.587,4.034C138.142,1.911 140.049,0.849 142.31,0.849C144.571,0.849 146.408,1.911 147.821,4.034L162.022,76.01ZM144.211,42.676L142.313,30.863L140.197,42.676L144.211,42.676Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M205.195,83.234C202.424,83.333 200.41,81.846 199.154,78.771L199.154,33.971L194.067,46.498C192.796,47.489 191.453,47.984 190.04,47.984C188.627,47.984 187.355,47.418 186.225,46.286L181.138,33.971L181.138,78.558C180.008,81.672 178.029,83.229 175.203,83.229C172.377,83.229 170.399,81.74 169.268,78.761L169.268,5.109C170.399,1.986 172.377,0.425 175.203,0.425C177.888,0.425 179.796,1.78 180.926,4.492L190.252,26.115L199.366,4.492C200.638,1.78 202.546,0.425 205.089,0.425C205.184,0.425 205.278,0.426 205.371,0.43C208.143,0.33 210.156,1.818 211.412,4.893L211.412,49.692L216.499,37.165C217.771,36.174 219.113,35.679 220.526,35.679C221.939,35.679 223.211,36.245 224.342,37.378L229.429,49.692L229.429,5.105C230.559,1.991 232.537,0.434 235.363,0.434C238.19,0.434 240.168,1.923 241.298,4.903L241.298,78.554C240.168,81.677 238.19,83.239 235.363,83.239C232.679,83.239 230.771,81.883 229.641,79.171L220.314,57.548L211.2,79.171C209.929,81.883 208.021,83.239 205.477,83.239C205.382,83.239 205.288,83.237 205.195,83.234Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M263.685,80.463C262.837,82.307 260.788,83.229 257.538,83.229C254.147,83.229 252.098,82.307 251.391,80.463L251.391,3.191C252.098,1.347 254.147,0.425 257.538,0.425C260.788,0.425 262.837,1.347 263.685,3.191L263.685,80.463Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M298.352,83.229L280.124,83.229C278.146,83.229 276.556,82.378 275.355,80.675C274.154,78.972 273.553,77.127 273.553,75.141L273.553,5.109C274.684,1.986 276.591,0.425 279.276,0.425C281.961,0.425 283.869,1.986 284.999,5.109L284.999,70.49L298.352,70.49C301.037,71.764 302.38,73.887 302.38,76.86C302.38,79.832 301.037,81.955 298.352,83.229Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M333.262,83.229L315.033,83.229C313.055,83.229 311.465,82.378 310.264,80.675C309.063,78.972 308.463,77.127 308.463,75.141L308.463,5.109C309.593,1.986 311.501,0.425 314.185,0.425C316.87,0.425 318.778,1.986 319.908,5.109L319.908,70.49L333.262,70.49C335.946,71.764 337.289,73.887 337.289,76.86C337.289,79.832 335.946,81.955 333.262,83.229Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M355.301,80.463C354.453,82.307 352.404,83.229 349.154,83.229C345.763,83.229 343.714,82.307 343.007,80.463L343.007,3.191C343.714,1.347 345.763,0.425 349.154,0.425C352.404,0.425 354.453,1.347 355.301,3.191L355.301,80.463Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M384.821,22.294C384.108,18.472 383.609,16.278 383.324,15.712C382.467,13.447 380.827,12.315 378.403,12.315C376.689,12.315 375.476,13.518 374.763,15.924C374.335,17.198 374.121,18.472 374.121,19.746C374.121,24.558 377.9,31.565 385.46,40.765C393.02,49.966 396.8,57.539 396.8,63.484C396.8,67.872 395.281,72.33 392.243,76.86C389.205,81.389 385.001,83.654 379.631,83.654C375.534,83.654 371.789,82.203 368.398,79.301C365.006,76.4 363.099,72.543 362.675,67.73L362.251,63.059L373.273,61.36C373.979,65.182 374.474,67.376 374.756,67.942C375.604,70.207 377.229,71.339 379.631,71.339C380.903,71.339 381.892,70.702 382.599,69.429C383.588,67.447 384.083,65.465 384.083,63.484C384.083,60.086 382.281,56.052 378.678,51.381C375.074,46.71 371.506,42.11 367.974,37.581C364.159,31.777 362.251,26.045 362.251,20.383C362.251,17.835 362.604,15.216 363.311,12.527C365.713,4.176 370.871,0 378.784,0C382.881,0 386.591,1.415 389.911,4.246C393.232,7.077 395.104,10.97 395.528,15.924L395.952,20.595L384.821,22.294Z"
                  style="fill-rule: nonzero"
                />
              </g>
              <g class="logo__creative">
                <path
                  d="M114.664,150.514L114.27,154.698C113.876,159.081 112.136,162.634 109.05,165.357C105.965,168.079 102.518,169.44 98.71,169.44C94.508,169.44 91.028,167.857 88.271,164.692C85.119,161.133 83.543,156.056 83.543,149.463L83.543,111.285C83.543,104.692 85.119,99.55 88.271,95.859C91.028,92.694 94.508,91.111 98.71,91.111C102.518,91.111 105.965,92.459 109.05,95.156C112.136,97.852 113.876,101.437 114.27,105.909L114.664,109.855L105.013,111.433C104.356,108.013 103.831,105.909 103.437,105.12C102.518,103.147 100.876,102.094 98.513,101.963C95.624,101.963 94.179,105.065 94.179,111.27L94.179,149.482C94.179,155.553 95.624,158.588 98.513,158.588C100.745,158.588 102.321,157.514 103.24,155.367C103.634,154.695 104.159,152.546 104.816,148.92L114.664,150.514Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M147.535,168.651C144.909,168.782 143.07,167.269 142.02,164.113L142.02,145.172C142.02,138.727 140.345,135.504 136.994,135.504L132.172,135.504L132.172,164.113C131.14,167.138 129.333,168.651 126.752,168.651C124.173,168.651 122.303,167.138 121.142,164.113L121.142,92.098L137.884,92.098C143.136,92.098 147.141,94.202 149.899,98.411C152,101.963 153.05,106.501 153.05,112.025L153.05,115.379C153.05,121.561 151.474,126.428 148.323,129.98C151.474,133.399 153.05,138.135 153.05,144.185L153.05,164.113C152,167.006 150.161,168.519 147.535,168.651ZM142.02,112.025C142.02,106.369 140.614,103.541 137.801,103.541L132.172,103.541L132.172,124.06L136.994,124.06C140.345,124.06 142.02,121.167 142.02,115.379L142.02,112.025Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M185.713,168.651L167.396,168.651C165.557,168.651 164.08,167.859 162.964,166.277C161.848,164.694 161.29,162.98 161.29,161.135L161.29,99.219C161.29,97.373 161.848,95.659 162.964,94.077C164.08,92.494 165.557,91.703 167.396,91.703L185.713,91.703C188.34,92.887 189.653,94.86 189.653,97.622C189.653,100.384 188.34,102.357 185.713,103.541L171.926,103.541L171.926,124.258L184.138,124.258C186.632,125.31 187.88,127.283 187.88,130.177C187.88,133.071 186.632,135.044 184.138,136.096L171.926,136.096L171.926,156.812L185.713,156.812C188.34,157.996 189.653,159.969 189.653,162.732C189.653,165.494 188.34,167.467 185.713,168.651Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M231.519,161.942C232.044,165.099 230.993,167.269 228.367,168.453C227.448,168.848 226.66,169.045 226.004,169.045C223.115,169.045 221.014,165.954 219.701,159.772C218.781,154.116 217.928,148.46 217.14,142.804L209.065,142.804C208.277,148.46 207.423,154.116 206.504,159.772C205.191,165.954 203.09,169.045 200.201,169.045C199.545,169.045 198.757,168.848 197.838,168.453C195.211,167.269 194.161,165.099 194.686,161.942L207.883,95.057C209.327,93.084 211.1,92.098 213.201,92.098C215.302,92.098 217.009,93.084 218.322,95.057L231.519,161.942ZM214.967,130.966L213.204,119.988L211.237,130.966L214.967,130.966Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M256.106,91.703C258.732,92.755 260.046,94.728 260.046,97.622C260.046,100.516 258.755,102.489 256.174,103.541L249.016,103.541L249.016,164.298C247.947,167.2 246.074,168.651 243.399,168.651C240.859,168.651 239.054,167.265 237.986,164.495L237.986,103.541L231.683,103.541C229.056,102.357 227.743,100.384 227.743,97.622C227.743,94.86 229.056,92.887 231.683,91.703L256.106,91.703Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M277.543,166.079C276.755,167.794 274.851,168.651 271.831,168.651C268.679,168.651 266.775,167.794 266.119,166.079L266.119,94.274C266.775,92.56 268.679,91.703 271.831,91.703C274.851,91.703 276.755,92.56 277.543,94.274L277.543,166.079Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M321.214,98.572L308.017,167.072C306.704,169.177 304.997,170.229 302.896,170.229C300.795,170.229 299.023,169.242 297.578,167.269L284.385,98.214C283.991,96.171 284.386,94.575 285.57,93.426C286.753,92.277 288.201,91.703 289.912,91.703C292.676,91.703 294.321,92.885 294.848,95.248L302.832,141.238C304.28,130.273 305.828,119.276 307.476,108.247C309.123,97.218 311.924,91.703 315.878,91.703C316.536,91.703 317.26,91.899 318.05,92.292C320.685,93.338 321.739,95.431 321.214,98.572Z"
                  style="fill-rule: nonzero"
                />
                <path
                  d="M351.164,168.651L332.846,168.651C331.008,168.651 329.53,167.859 328.414,166.277C327.298,164.694 326.74,162.98 326.74,161.135L326.74,99.219C326.74,97.373 327.298,95.659 328.414,94.077C329.53,92.494 331.008,91.703 332.846,91.703L351.164,91.703C353.79,92.887 355.103,94.86 355.103,97.622C355.103,100.384 353.79,102.357 351.164,103.541L337.376,103.541L337.376,124.258L349.588,124.258C352.083,125.31 353.33,127.283 353.33,130.177C353.33,133.071 352.083,135.044 349.588,136.096L337.376,136.096L337.376,156.812L351.164,156.812C353.79,157.996 355.103,159.969 355.103,162.732C355.103,165.494 353.79,167.467 351.164,168.651Z"
                  style="fill-rule: nonzero"
                />
              </g>
              <g class="logo__symbol">
                <g transform="matrix(-1,0,0,1,72.564,0)">
                  <path
                    class="logo__line"
                    d="M6.498,50.239L21.103,85.351L36.056,49.75L36.056,120.597L51.294,85.013L66.066,120.371"
                  />
                </g>
                <path
                  class="logo__border"
                  d="M66.209,169.826L5.903,169.826C2.749,169.671 0.158,167.133 0,163.912L0,6.339C0.155,3.179 2.689,0.583 5.903,0.425L66.209,0.425C66.402,0.434 66.595,0.444 66.788,0.453C69.713,0.888 71.964,3.32 72.112,6.339L72.112,163.912C71.957,167.081 69.424,169.668 66.209,169.826ZM60.306,157.998L60.306,12.253L11.806,12.253L11.806,157.998L60.306,157.998Z"
                />
              </g>
            </g>
          </svg>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
